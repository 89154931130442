import Cookies from 'js-cookie'
import type { EcommerceParserConfig, AllClientParsers } from '../types/tracker'

import {
  createUniqueSubmissionCookieValue,
  isListingUniqueSubmission,
  getListingLeadSubmissionIdsCookieExpirationTime,
  UNIQUE_LEAD_SUBMISSION_COOKIE_NAME,
} from '../helpers/unique-lead-submission'

const defaultConfig = {
  campaignKey: 'campaign_id',
  sessionKey: 'rp_session_id',
}

const DEFAULT_CAMPAIGN_FACTOR = 1.75

function requestId(): number {
  return Date.now() + Math.floor((1 + Math.random()) * 1000)
}

export function multiplierMatrix(
  ecommerceConfig: EcommerceParserConfig
): number {
  const theDate = new Date()
  const theWeekDay = theDate.getDay()
  const theHours = theDate.getHours()

  return (
    (ecommerceConfig.multiplierMatrix &&
      ecommerceConfig.multiplierMatrix[theWeekDay] &&
      ecommerceConfig.multiplierMatrix[theWeekDay][theHours]) ||
    DEFAULT_CAMPAIGN_FACTOR
  )
}

export function calculateFactor(
  config: EcommerceParserConfig,
  campaignId: string,
  selection?: string,
  screenType?: string
) {
  if (selection === 'email' && screenType === 'desktop') {
    if (
      campaignId &&
      config.multiplierMatrix &&
      config.multiplierMatrix.length > 0
    ) {
      return multiplierMatrix(config)
    } else if (campaignId) {
      return DEFAULT_CAMPAIGN_FACTOR
    }
    return 2
  }
  return 1
}

const adjustedTotal = (factor: number, revenue?: string) => {
  // A bit hacky because I didn't want to create more typeguards here.
  // This works.
  return revenue && !isNaN(revenue as unknown as number)
    ? (parseFloat(revenue) * factor).toFixed(2)
    : revenue
}

const reducer =
  (config: Required<Pick<EcommerceParserConfig, 'campaignKey'>>) =>
  (dataToMergeWith: AllClientParsers) => {
    if (
      !dataToMergeWith.action ||
      dataToMergeWith.action !== 'lead_submission'
    ) {
      return dataToMergeWith
    }

    const transactionId = requestId()
    const { campaignKey } = config
    const { listing_id, revenue, selection } = dataToMergeWith
    const cookies = Cookies.get()
    const uniqueSubmission = isListingUniqueSubmission({
      listingId: listing_id || '',
      listingLeadSubmissionIdsArrayAsString:
        cookies[UNIQUE_LEAD_SUBMISSION_COOKIE_NAME],
    })

    const factor = calculateFactor(
      config,
      cookies[campaignKey],
      typeof selection === 'string' ? selection : undefined,
      dataToMergeWith.screen_type
    )
    const transactionAdjustedTotal = adjustedTotal(factor, revenue)

    Cookies.set(
      UNIQUE_LEAD_SUBMISSION_COOKIE_NAME,
      createUniqueSubmissionCookieValue({
        listingLeadSubmissionIdsArrayAsString:
          cookies[UNIQUE_LEAD_SUBMISSION_COOKIE_NAME],
        listingId: listing_id || '',
      }),
      {
        domain: window.location.hostname,
        expires: getListingLeadSubmissionIdsCookieExpirationTime(),
      }
    )

    return {
      ...dataToMergeWith,
      transactionId,
      transactionAffiliation: selection,
      transactionAdjustedTotal,
      transactionTotal: revenue,
      uniqueSubmission: `${uniqueSubmission}`,
      transactionProducts:
        '[{sku:' +
        listing_id +
        ',name:' +
        listing_id +
        ',category:' +
        selection +
        ',price:' +
        revenue +
        ',quantity:1}]',
    }
  }

export const ecommerce = (ecommerceConfig?: EcommerceParserConfig) => {
  if (ecommerceConfig) {
    return reducer({
      ...defaultConfig,
      ...ecommerceConfig,
    })
  }

  return reducer(defaultConfig)
}
