import { Host } from '../../../config/host'

import type { Credentials } from '../../../features/tagging/tagging.types'

// tagging profile
export const profile: Record<Host, string> = {
  [Host.rce]: 'apartment_guide',
  [Host.prod]: 'apartment_guide',
  [Host.re]: 'qa',
  [Host.qa]: 'qa',
  [Host.ci]: 'dev',
  [Host.local]: 'dev',
} as const

// tagging subprofile
export const subprofile: Record<Host, string> = {
  [Host.rce]: 'apartmentguide.com',
  [Host.prod]: 'apartmentguide.com',
  [Host.re]: 'qa.apartmentguide.com',
  [Host.qa]: 'qa.apartmentguide.com',
  [Host.ci]: 'ci.apartmentguide.com',
  [Host.local]: 'ci.apartmentguide.com',
}

// gtm setup options
export const credentials: Credentials = {
  standard: {
    id: 'GTM-NCWK9PZ',
    auth: {
      [Host.prod]: '0dhfYeT4lIItBEgVXCJYKQ',
      [Host.rce]: 'kyIUXh707-1v2Jqlmnq6bg',
      [Host.qa]: 'zJuBGICnXlwhFbE9YkowLw',
      [Host.re]: 'zJuBGICnXlwhFbE9YkowLw',
      default: 'RU12uCj6hbP6VJGYJISbWg',
    },
    preview: {
      [Host.prod]: 'env-2',
      [Host.rce]: 'env-1124',
      [Host.qa]: 'env-6',
      [Host.re]: 'env-6',
      default: 'env-8',
    },
  },
  gdpr: {
    id: 'GTM-MLQCVMM',
    auth: {
      [Host.prod]: '-5sKM0dDjdfnC3_BDhBPgg',
      [Host.qa]: '8DWcbP0JBNiQFDEabNKGcg',
      [Host.ci]: 'S37pRBixIntUdKQtqX2nDg',
      [Host.re]: 'YOYgQt-lJBM9ekL_HI-Syw',
      default: 'cqq6sOXZPv1rTxcIVHZbmQ',
    },
    preview: {
      [Host.prod]: 'env-2',
      [Host.qa]: 'env-98',
      [Host.ci]: 'env-99',
      [Host.re]: 'env-101',
      default: 'env-100',
    },
  },
}

// This array is used by event-tracker to determine a factor for calculating
// transactionAdjustedTotal in the dataLayer.  Only desktop email leads with a
// campaign_id cookie will be affected.

// prettier-ignore
export const multiplierMatrix = [
  [
    1.0156, 1.0272, 1.0111, 1.0144, 1.0075, 1.0137, 1.0223,
    1.0283, 1.1252, 1.2035, 1.224, 1.3265, 1.4627, 1.4944,
    1.4927, 1.4251, 1.3733, 1.26, 1.1606,
    1.095, 1.0525, 1.0413, 1.0263, 1.0144
  ],
  [
    1.0059, 1.0059, 1.0149, 1.0037, 1.0198, 1.0099, 1.0238,
    1.1764, 1.3646, 2.0907, 2.4449, 2.5164, 2.4763, 2.3261,
    2.1813, 2.1967, 2.2364, 2.2324,1.6932, 1.3304, 1.1863,
    1.0605, 1.038, 1.0149
  ],
  [
    1.0129, 1.0218, 1.0146, 1.0068, 1.0087, 1.0028, 1.0582,
    1.0972, 1.3093, 2.0186, 2.4238, 2.422, 2.3756, 2.2681,
    2.2208, 2.4264, 2.3739, 2.1346, 1.5733, 1.3227, 1.2099,
    1.0891, 1.0486, 1.0188
  ],
  [
    1.0165, 1.0284, 1.0116, 1.0001, 1.007, 1.0139, 1.1171,
    1.0862, 1.3644, 1.9757, 2.1589, 2.392, 2.475, 2.2943,
    2.5817, 2.5848, 2.2234, 2.2319, 1.707, 1.3444, 1.2226,
    1.0978, 1.0474, 1.0215
  ],
  [
    1.0167, 1.0113, 1.0214, 1.0178, 1.015, 1.0169, 1.0493,
    1.0835, 1.3231, 2.0542, 2.3451, 2.5949, 2.5109, 2.4244,
    2.4741, 2.3457, 2.3294, 2.0737, 1.6309, 1.3828, 1.2097,
    1.1259, 1.0506, 1.0249
  ],
  [
    1.0095, 1.0104, 1.0172, 1.0172, 1.0128, 1.026, 1.0776,
    1.0952, 1.3777, 2.3358, 2.4693, 2.5729, 2.5422, 2.7595,
    2.2959, 2.3493, 2.2736, 2.0836, 1.7312, 1.4728, 1.2229,
    1.1168, 1.0637, 1.0196
  ],
  [
    1.0088, 1.0011, 1.0157, 1.012, 1.0243, 1.0328, 1.1967,
    1.4311, 1.9259, 2.5945, 2.6581, 2.3282, 2.1434, 2.1767,
    1.7804, 1.7168, 1.4669, 1.262, 1.1606, 1.1073, 1.061,
    1.0252, 1.0273, 1.0552
  ],
]

export const measurementIds = {
  qa: 'XYGMV2JEF8',
  prod: 'LSGT55PFTP',
} as const

// GA Session ID map by host
export const gaSessionIdMap: Record<Host, string> = {
  [Host.rce]: measurementIds.prod,
  [Host.prod]: measurementIds.prod,
  [Host.re]: measurementIds.qa,
  [Host.qa]: measurementIds.qa,
  [Host.ci]: measurementIds.qa,
  [Host.local]: measurementIds.qa,
}
