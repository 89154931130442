import { credentials, multiplierMatrix } from '@brand/config/tagging'
import type { TrackerConfig } from '@rentpath/google-event-tracking'

const session = {
  visitorKey: 'rp_visit_id',
  sessionKey: 'rp_session',
}

const ecommerceSession = Object.assign({ multiplierMatrix }, session)

export function createTrackerConfig(
  isGDPR: boolean,
  hostEnv: keyof typeof credentials.standard.auth
): TrackerConfig {
  const configType = isGDPR ? 'gdpr' : 'standard'
  const config = credentials[configType]

  return {
    gtmId: config.id,
    gtmAuth: config.auth[hostEnv] ?? config.auth.default,
    gtmPreview: config.preview[hostEnv] ?? config.preview.default,
    clientParsers: [
      ['session', session],
      'browser',
      'device',
      'element',
      'event',
      'tagmanager',
      ['ecommerce', ecommerceSession],
    ],
    trackCallback: () => {
      window.rentpathEventBus?.dispatch('GTMEvent')
    },
    trackers: ['click', 'select'],
  }
}
