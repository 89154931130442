import * as allClientParsers from './client-parsers/parsers'
import cookie from './utils/cookie'
import { sanitize } from './utils/utils'
import { normalizeEvent } from './helpers/dom-helpers'
import { IGNORE_ACTION, VIEW_ACTION, DEFAULT_CONFIG } from './const'
import { yieldOrContinue } from 'main-thread-scheduling'

import type {
  TrackerConfig,
  AllClientParsers,
  CanTrack,
  TrackerState,
} from './types/tracker'

type TrackerCancelNext = {
  action: string
  session_id: string
}

const CANCEL_NEXT_MAX_MS = 3000
const INTERACTIVE_ELEMENT_TYPES = [
  'A',
  'BUTTON',
  'LABEL',
  'INPUT',
  'OPTION',
  'SELECT',
  'TEXTAREA',
]

export class Tracker {
  utils = cookie

  config: TrackerConfig
  eventListeners?: Map<
    string,
    {
      clear: () => typeof document.removeEventListener
    }
  >
  cancelQueue: TrackerCancelNext[] = []
  parsers?: ReturnType<typeof allClientParsers[keyof typeof allClientParsers]>[]
  reset?: () => void

  private _data: Record<string, never> | AllClientParsers = {}
  private _state: TrackerState = {
    initialPageview: 1,
  }

  constructor(initialConfig: TrackerConfig) {
    window.dataLayer = window.dataLayer || []

    this.config = {
      ...DEFAULT_CONFIG,
      ...initialConfig,
    }
    this.cancelQueue = []

    if (this.validateConfig(this.config)) {
      // Returns a Map of current listeners, and a clear() function to remove that listener
      this.eventListeners = this.setupEventTrackers(this.config)

      // Returns an array of parser functions, their arguments is data to be submitted
      // to GTM Datalayer.
      this.parsers = this.setupClientParsers(this.config)

      // Returns a function that resets the GTM
      this.reset = this.bootGtm()
    }
  }

  validateConfig(config: TrackerConfig): boolean {
    const required: (
      | 'gtmId'
      | 'gtmAuth'
      | 'gtmPreview'
      | 'trackers'
      | 'clientParsers'
    )[] = ['gtmId', 'gtmAuth', 'gtmPreview', 'trackers', 'clientParsers']

    required.forEach((requiredKey) => {
      if (!config[requiredKey]) {
        throw new Error(`Missing ${requiredKey} in GTM configuration!`)
      }
    })

    return true
  }

  setupEventTrackers({ trackers }: TrackerConfig) {
    const eventTrackerMap = new Map()
    const interactiveElSelector = `:is(${INTERACTIVE_ELEMENT_TYPES.join(', ')})`
    const eventListenerSetup =
      (internalEventName: string, canTrack?: CanTrack) =>
      async (event: Event) => {
        await yieldOrContinue('interactive')
        // Limit tracking to only interactive elements and their children
        const el: Element | null = event.target as Element
        const isInteractiveElement = el
          ? INTERACTIVE_ELEMENT_TYPES.includes(el.tagName?.toUpperCase())
          : false
        const isAncestorOfInteractiveElement =
          !isInteractiveElement && el && el.closest
            ? el.closest(interactiveElSelector) !== null
            : false

        if (!isInteractiveElement && !isAncestorOfInteractiveElement) {
          return false
        }

        if (!canTrack) {
          return this.track(internalEventName, { event })
        }

        if (canTrack(event.target as Node)) {
          return this.track(internalEventName, { event })
        }

        return
      }

    trackers.forEach((tracker) => {
      const [eventType, internalEventName, canTrack] = normalizeEvent(tracker)

      if (eventType && internalEventName) {
        const eventCallback = eventListenerSetup(internalEventName, canTrack)
        document.addEventListener(eventType, eventCallback)

        /**
         * Added ability to clear eventListeners
         */
        eventTrackerMap.set(eventType, {
          clear: () => document.removeEventListener(eventType, eventCallback),
        })
      }
    })

    return eventTrackerMap
  }

  /**
   * @description - We loop through the curried parser functions whose signature is `fn0(config) -> fn1(data) -> fn2()`
   * If the parser is an array, it has config options
   *    parser = name of parser, 'browser' | 'device' | 'ecommerce' | 'element' | 'event' | 'session' | 'tagmanager'
   * if parser is Array:
   *    parser[0] = name of parser, 'browser' | 'device' | 'ecommerce' | 'element' | 'event' | 'session' | 'tagmanager'
   *    parser[1] = config of that particular parser. Only 'device' | 'ecommerce' | 'session' is configurable
   */
  setupClientParsers({ clientParsers }: TrackerConfig) {
    if (clientParsers) {
      return clientParsers.map((parser) => {
        if (Array.isArray(parser)) {
          return allClientParsers[parser[0]](parser[1])
        }

        return allClientParsers[parser]()
      })
    }

    return
  }

  parse(dataToMergeWith: AllClientParsers) {
    return (
      this.parsers &&
      sanitize(
        this.parsers.reduce<any>((acc, curr) => curr(acc), dataToMergeWith)
      )
    )
  }

  push(data: Record<string, string>) {
    window.dataLayer.push(data)
  }

  track(action: string, props: object) {
    ;(async () => {
      await yieldOrContinue('interactive')

      const { arch, trackCallback } = this.config
      const data = this.parse({ ...{ action }, ...this._data, ...props, arch })

      if (!data) {
        return this
      }

      if (data && data.action === IGNORE_ACTION) {
        return this
      }

      if (this.isDataTrackCancelled(data)) {
        return this
      }

      if (typeof trackCallback === 'function') {
        data.eventCallback = () => trackCallback(data)
      }

      if (
        data.event &&
        data.event === 'gtm.view' &&
        this._state.initialPageview === 1
      ) {
        // Tagging team request that all initial gtm.view
        // are initialView = 1
        data.initialPageview = 1
        this._state = {
          ...this._state,
          initialPageview: 0,
        }
      } else {
        data.initialPageview = 0
      }

      if (data && data.event === 'gtm.view') {
        this.push({ ...data, event: 'gtm.pageinfo' })
        this.push(data)
      } else {
        this.push(data)
      }
    })()

    return void 0
  }

  include(data: object, merge = true) {
    this._data = {
      ...((merge && this._data) || {}),
      ...data,
    }

    return this
  }

  view<T extends object>(args: T) {
    return this.track(VIEW_ACTION, args)
  }

  isDataTrackCancelled(data: Record<string, string>): boolean {
    // Check if event is cancelled by previous event with same session_id (to prevent dupes)
    const cqlen = this.cancelQueue.length
    if (cqlen && cqlen !== 0) {
      for (let i = 0; i < cqlen; i++) {
        const qItem = this.cancelQueue[i]

        // Matching event, don't track this one + remove item from cancelQueue
        if (
          qItem &&
          qItem.action === data?.action &&
          qItem.session_id === data?.session_id
        ) {
          delete this.cancelQueue[i]
          return true
        }
      }
    }

    // Cancel next action with same session_id?
    if (data.cancel_next && data.session_id) {
      const cancelData = {
        action: data.cancel_next,
        session_id: data.session_id,
      }
      this.cancelQueue.push(cancelData)

      // Max. time for current event cancellation to be in effect
      setTimeout(() => {
        this.cancelQueue = this.cancelQueue.filter((ci) => ci !== cancelData)
      }, CANCEL_NEXT_MAX_MS)
    }

    return false
  }

  /**
   * @description We want to boot up the GTM script as soon as possible.
   * First we send the gtm.pageinfo details, then we send the `gtm.js`
   *
   * @returns - a reset function, to restart GTM
   */
  bootGtm(): () => void {
    const { dataLayer } = window

    if (this.parsers) {
      dataLayer.push({
        event: 'gtm.js',
        'gtm-start': new Date().getTime(),
        ...this.parsers.reduce((acc, curr) => curr(acc), {}),
      })

      return () => {
        const { gtmId } = this.config

        if (
          gtmId &&
          window.google_tag_manager &&
          window.google_tag_manager[gtmId]
        ) {
          window.google_tag_manager[gtmId].dataLayer.reset()
        }
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {}
  }
}

if (
  typeof window !== 'undefined' &&
  window.eventTrackerSettings &&
  !window.eventTracker
) {
  window.eventTracker = new Tracker(window.eventTrackerSettings)

  // Not sure what was going on here... but it can't be right?
  // window.eventTracker.utils.createCookie;

  if (window.rentpathEventBus) {
    const { rentpathEventBus } = window

    rentpathEventBus.dispatch('TrackerLoadEvent')
  }
}
